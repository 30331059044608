import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { filter, take } from 'rxjs/operators';
import Summary from '../Minicart/Summary';
import CheckoutIncompleteProfile from './IncompleteProfile';
import CheckoutCompletedProfile from './CompletedProfile';

import * as Styled from './styles';
import { selectUser$ } from '../../state/user/user.service';
import { graphql, useStaticQuery } from 'gatsby';

import Back from './../ui/GoBackLink';

const Checkout = ({ locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiCheckoutPage_en: strapiCheckoutPage(locale: { eq: "en" }) {
        back_to_shop_link_text
      }
      strapiCheckoutPage_zh: strapiCheckoutPage(locale: { eq: "zh" }) {
        back_to_shop_link_text
      }
    }
  `);

  const translations = gql[`strapiCheckoutPage_${locale}`];

  const [user, setUser] = useState(null);
  const [isProfileComplete, setProfileComplete] = useState(false);

  useEffect(() => {
    selectUser$
      .pipe(
        filter((user) => !!user.id),
        take(1)
      )
      .subscribe((user) => {
        const profileCompleted =
          !!user.addresses &&
          !!user.addresses.length &&
          !!user.email &&
          !!user.phone &&
          !!user.birth_date;

        setUser(user);
        setProfileComplete(profileCompleted);
      });
  }, []);

  return (
    <div className="boxed">
      <Styled.Wrapper>
        <div className="grid grid-cols-12 gap-6 grid-wrapper">
          <div className="col-span-4 hidden sm:block">
            <Back title={translations.back_to_shop_link_text} link="/shop" />
            <div className="bg-white mt-2.5">
              <Summary locale={locale} />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-8 mt-7">
            {isProfileComplete && <CheckoutCompletedProfile user={user} locale={locale} />}
            {!isProfileComplete && <CheckoutIncompleteProfile user={user} locale={locale} />}
          </div>
        </div>
      </Styled.Wrapper>
    </div>
  );
};

Checkout.propTypes = {
  locale: PropTypes.string,
};

Checkout.defaultProps = {
  locale: 'en',
};

export default Checkout;
