import styled from 'styled-components';
import tw from 'twin.macro';


export const Button = styled.button`
  outline: none !important;
  letter-spacing: 0.03em;
  ${tw`w-full mt-1 py-2 px-1 sm:px-8 border border-black text-black font-semibold text-xssemi`};

  ${({ primary }) => (primary ? tw`bg-black text-white hover:text-white` : tw``)};

  ${({ block }) => block && tw`w-full`};

  ${({ addToCart }) => (addToCart ? tw`flex-1` : tw``)};
`;
