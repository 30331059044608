import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import * as Styled from './styles';
import PropTypes from 'prop-types';
import NewAddressForm from './NewAddressForm';
import { graphql, useStaticQuery } from 'gatsby';
import { Form } from 'formik';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { deleteCustomerAddress, getCustomerProfile, selectUser$ } from '../../../state/user/user.service';
import Danger from "../../ui/Alert/Danger";
import CloseBtn from "../../ui/icons/close";
import Button from './../../ui/ButtonStyle'

const NewAddress = ({ locale, type, closeAddressForm, address, errors }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiCheckoutPage_en: strapiCheckoutPage(locale: { eq: "en" }) {
        add_new_address_caption
        close_button_text
        use_address_button_text
        edit
        edit_address
        save_address_button_text
        update_address_button_text
        delete_address_button_text
      }
      strapiCheckoutPage_zh: strapiCheckoutPage(locale: { eq: "zh" }) {
        add_new_address_caption
        close_button_text
        use_address_button_text
        edit
        edit_address
        save_address_button_text
        update_address_button_text
        delete_address_button_text
      }
    }
  `);
  const translations = gql[`strapiCheckoutPage_${locale}`];
  const [unMount$] = useState(new Subject());
  const [isNewAddress, setNewAddress] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (closeAddressForm) {
      closeAddressForm.pipe(takeUntil(unMount$)).subscribe((cc) => {
        setNewAddress(false);
      });
    }

    selectUser$.pipe(takeUntil(unMount$)).subscribe((user) => {
      setUser(user);
    });

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  const toggleModal = () => {
    setNewAddress(false);
  };

  return (
    <>
      {type === 'edit' ? (
        <button type="button" onClick={() => setNewAddress(true)}>
          [ {translations.edit} ]
        </button>
      ) : (
        <Styled.NewAddress>
          <a onClick={() => setNewAddress(true)}>[ {translations.add_new_address_caption} ]</a>
        </Styled.NewAddress>
      )}

      <Modal
        isOpen={isNewAddress}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        id="newAddressModal"
      >
        <button onClick={toggleModal} className="float-right mb-4">
          <CloseBtn/>
        </button>

        {type === 'edit' && <h1 className={`mb-4`}>{translations.edit_address}</h1>}
        {type === 'new' && <h1 className={`mb-4`}>{translations.add_new_address_caption}</h1>}
        <Form>
          <NewAddressForm locale={locale} />

          <Styled.Button>
            <div className='flex flex-row-reverse gap-3 w-full'>
              {type === 'new' && <Button black>{translations.save_address_button_text}</Button>}
              {type === 'edit' && <Button black>{translations.update_address_button_text}</Button>}
              <button onClick={toggleModal} className='closeBtn'>{translations.close_button_text}</button>
              {type === 'edit' && user && user.addresses && user.addresses.length > 1 && (
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    deleteCustomerAddress(address.id)
                      .pipe(take(1))
                      .subscribe(async () => {
                        await getCustomerProfile();
                        toggleModal();
                      });
                  }}
                  className='my-auto mx-2 cursor-pointer'
                >
                  {translations.delete_address_button_text}
                </p>
              )}
            </div>
          </Styled.Button>
        </Form>
        {errors.map((err) => <Danger message={err} />)}
      </Modal>
    </>
  );
};

NewAddress.propTypes = {
  locale: PropTypes.string,
  type: PropTypes.string,
  errors: PropTypes.array,
};

NewAddress.defaultProps = {
  locale: 'en',
  errors: [],
};

export default NewAddress;
