import styled from 'styled-components';
import tw from 'twin.macro';

export const Desktop = styled.div`
  ${tw`hidden sm:block`};
`;

export const Mobile = styled.div`
  ${tw`block sm:hidden`};
  .header{
    ${tw`text-center mb-16 mt-4`};
    h1{
      letter-spacing: 0.04em;
      ${tw`text-grayu-basic text-xl3 font-bold`};
    }
    p{ ${tw`text-sm text-brown-darker font-medium`}; }
  }
  .addressBlock{
    border-color: #0963CD;
    ${tw`mt-16 mx-4 text-center border-2 items-center rounded-md p-2 my-4`};
    svg{${tw`w-5 h-5 mx-auto my-2`};}
    p{${tw`text-blacku-medium text-sm font-semibold my-2`};}
    label{${tw`text-brown-darker text-sm my-2`};}
  }
`;

export const Wrap = styled.div`
  ${tw`bg-white mb-6 pb-6 px-4 sm:px-0`};
  margin-bottom: 32px;
  label{
    ${tw`text-blacku-medium text-xs`};
  }
  input{
    letter-spacing: 0.3px;
    ${tw`text-brown-darker text-xssemi`};
  }
  .pBase{
    margin: 24px 16px 0;
  }
`;

export const HeaderWrap = styled.h4`
  line-height: 19px;
  letter-spacing: 0.04em;
  padding: 12px 16px 10px 16px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: #4D4545;
  ${tw`border-b border-gray-200`};
`;

export const GridWrap = styled.div`
  margin-top:24px;
  ${tw`w-full`};
  p{
    ${tw`mt-8 pb-2.5`};
    font-size: 12px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #383C3F;
    margin: 0px 16px;
  }
`;

export const ColWrap = styled.div`
  margin-top:0 !important;
  ${tw`col-span-2 pt-2 mx-4`};
`;

export const Col2Wrap = styled.div`
  margin-top:0 !important;
  ${tw`col-span-2 sm:col-span-1 flex flex-col pt-2`};
`;

export const Address = styled.div`
  letter-spacing: 0.03em;
  padding: 16px;
  border: 1px solid #D8D8D8;
  box-sizing: border-box;
  border-radius: 4px;
  ${tw`flex w-full items-center`};
  input{
    ${tw`block focus:outline-none h-4 w-4 text-blue-500 border-gray-300`};
  }
  label{
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: #000000;
    margin-left: 16px;
    div{
      label{
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.03em;
        color: #737373;
        margin-left: 1px;
        margin-top: 2px;
      }
    }
  }
  &.active{
    border: 1px solid #0963CD;
  }
  .editBtn{
    ${tw`hidden sm:block w-2/12 text-right`};
    button{
      color: #0963CD;
      letter-spacing: 0.08em;
      ${tw`text-xs cursor-pointer`};
    }
  }
  .flexStart{
    ${tw`flex items-start text-mini`};
  }
  svg{
    color: #CBC9C9;
    width: 12px;
    height: 12px;
    margin-top: -5px;
  }
`;

export const NewAddress = styled.div`
  ${tw`mt-4 space-y-4`};
  a{
    color: #0963CD;
    letter-spacing: 0.08em;
    ${tw`text-xs cursor-pointer`};
  }
`;

export const ButtonWrap = styled.div`
  margin-top: 24px;
  padding: 0 1rem;
  button.main{
    background: #0D090E;
    width: 100%;
    font-size: 16px;
    line-height: 48px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
  }
  button.disabledBtn{
    margin-top: 0;
  }
  div{
    ${tw`sm:hidden w-full mx-auto my-6`};
    a{
      color: #0963CD;
      letter-spacing: 0.08em;
      ${tw`flex w-2/5 mx-auto text-xs cursor-pointer`};
      svg{${tw`h-4 w-4`};}
    }
  }
`;
