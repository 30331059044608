import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';
import * as Styled from './styles';
import { graphql, useStaticQuery } from 'gatsby';
import Error from '../../../assets/images/error.png';
import Check from '../../../assets/images/icon-check.png';
import MapIcon from '../../ui/icons/map';
import { australianStates } from '../../../state/utils';

const NewAddressForm = ({ locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiCheckoutPage_en: strapiCheckoutPage(locale: { eq: "en" }) {
        add_new_address_caption
        delivery_address_form_heading
        delivery_address_suburb_field_label
        delivery_address_state_field_label
        delivery_address_postcode_field_label
        delivery_address_place_name_field_label
        enter_full_address_placeholder
        please_select_state_placeholder
      }
      strapiCheckoutPage_zh: strapiCheckoutPage(locale: { eq: "zh" }) {
        add_new_address_caption
        delivery_address_form_heading
        delivery_address_suburb_field_label
        delivery_address_state_field_label
        delivery_address_postcode_field_label
        delivery_address_place_name_field_label
        enter_full_address_placeholder
        please_select_state_placeholder
      }
    }
  `);
  const translations = gql[`strapiCheckoutPage_${ locale }`];

  return (
    <>
      <div className="">
        <Styled.Delivery className="order-3">
          <div>
            <label htmlFor="comments">{ translations.delivery_address_form_heading }</label>
            <div>
              <Field type="text" name="address_line_1">
                { ({ field, meta: { touched, error } }) => (
                  <div className="relative">
                    <input
                      name="address_line_1"
                      placeholder={translations.enter_full_address_placeholder}
                      className={ touched && error ? 'errorInput' : '' }
                      { ...field }
                    />
                    <img
                      src={ touched && error ? Error : Check }
                      alt="error"
                      className={ !touched ? 'errorIconHide' : 'errorIconShow' }
                    />
                    <MapIcon/>
                  </div>
                ) }
              </Field>
              <ErrorMessage name="address_line_1" component="div" className="errorMessage"/>
            </div>
          </div>
        </Styled.Delivery>

        <Styled.Suburb>
          <div className="col-span-6 sm:col-span-2">
            <label htmlFor="suburb"> { translations.delivery_address_suburb_field_label } </label>
            <Field type="text" name="city">
              { ({ field, meta: { touched, error } }) => (
                <div className="relative">
                  <input className={ touched && error ? 'errorInput' : '' } { ...field } />
                  <img
                    src={ touched && error ? Error : Check }
                    alt="error"
                    className={ !touched ? 'errorIconHide' : 'errorIconShow' }
                  />
                </div>
              ) }
            </Field>
            <ErrorMessage name="city" component="div" className="errorMessage"/>
          </div>
          <div className='col-span-3 sm:col-span-2'>
            <label htmlFor="state"> { translations.delivery_address_state_field_label } </label>
            <Field component="select" name="state" multiple={ false } className="border border-gray-300 mt-1 text-xs">
              <option value=''>{ translations.please_select_state_placeholder }</option>
              { australianStates.map(state => <option value={ state.id }>{ state.value }</option>) }
            </Field>
            <ErrorMessage name="state" component="div" className="errorMessage"/>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <label htmlFor="postcode"> { translations.delivery_address_postcode_field_label } </label>
            <Field type="text" name="postal_code">
              { ({ field, meta: { touched, error } }) => (
                <div className="relative">
                  <input
                    name="postal_code"
                    className={ touched && error ? 'errorInput' : '' }
                    { ...field }
                  />
                  <img
                    src={ touched && error ? Error : Check }
                    alt="error"
                    className={ !touched ? 'errorIconHide' : 'errorIconShow' }
                  />
                </div>
              ) }
            </Field>
            <ErrorMessage name="postal_code" component="div" className="errorMessage"/>
          </div>
        </Styled.Suburb>

        <Styled.NamePlace>
          <div>
            <label htmlFor="label"> { translations.delivery_address_place_name_field_label } </label>
            <Field type="text" name="label">
              { ({ field, meta: { touched, error } }) => (
                <div className="relative">
                  <input name="label" className={ touched && error ? 'errorInput' : '' } { ...field } placeholder="i.e Home, Work, etc..."/>
                  <img
                    src={ touched && error ? Error : Check }
                    alt="error"
                    className={ !touched ? 'errorIconHide' : 'errorIconShow' }
                  />
                </div>
              ) }
            </Field>
            <ErrorMessage name="label" component="div" className="errorMessage"/>
          </div>
        </Styled.NamePlace>
      </div>
    </>
  );
};

NewAddressForm.propTypes = {
  locale: PropTypes.string,
};

NewAddressForm.defaultProps = {
  locale: 'en',
};

export default NewAddressForm;
