import styled from 'styled-components';
import tw from 'twin.macro';

export const Delivery = styled.div`
  ${tw`flex items-start mt-3 sm:mt-0`};
  div{
    ${tw`text-sm w-full`};
    label{
      margin-top: 72.5px;
      margin-bottom: 22.5px;
      ${tw`font-sans tracking-normal text-xs font-medium text-blacku-medium`};
    }
    div{
      ${tw`relative`};
      input{
        ${tw`mt-1 block w-full pl-8 text-xs`};
      }
      svg{
        ${tw`h-4 w-4 absolute top-3 left-2 text-blacku-medium`};
      }
    }
  }
  .errorMessage{
    font-size: 12px;
    line-height: 135%;
    letter-spacing: 0.3px;
    color: #EB5656;
  }
`;

export const Suburb = styled.div`
  ${tw`grid grid-cols-5 gap-6 mt-6`};
  div{
    label{
      ${tw`font-sans tracking-normal block text-xs font-medium text-blacku-medium`};
    }
    input{
      ${tw`mt-1 block w-full text-xs`};
    }
  }
`;

export const NamePlace = styled.div`
  ${tw`grid grid-cols-1 gap-6 mt-6`};
  div{
    ${tw`col-span-6 sm:col-span-2`};
    label{
      ${tw`font-sans tracking-normal block text-xs font-medium text-blacku-medium`};
    }
    input{
      ${tw`mt-1 block w-full text-xs`};
    }
  }
`;

export const SaveAddress = styled.div`
  ${tw`grid grid-cols-1 gap-6 mt-6`};
  input{
    ${tw`focus:outline-none h-4 w-4 rounded`};
  }
  label{
    ${tw`font-sans tracking-normal text-xssemi font-normal text-brown-darker`};
  }
`;

export const Place = styled.div`
  ${tw`grid grid-cols-1 gap-6 mt-6`};
  label{
    ${tw`font-sans tracking-normal block text-xs font-medium text-blacku-medium`};
  }
  input{
    ${tw`mt-1 block w-full text-xs `};
  }
`;

export const Button = styled.div`
  ${tw`flex mt-16`};
  .closeBtn{
    line-height: 40px;
    ${tw`bg-white text-black border-black border text-ft-16 tracking-ls-5 px-4`};
  }
`;

export const NewAddress = styled.div`
  margin-top: 16px;
  a{
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #0963CD;
    ${tw`cursor-pointer`};
  }
`;

export const Address = styled.div`
  letter-spacing: 0.03em;
  border-color: #D8D8D8;
  ${tw`flex w-full  items-center shadow-sm border rounded-md px-2 py-4 mb-3`};
  input{
    ${tw`block focus:outline-none h-4 w-4 text-blue-500 border-gray-300`};
  }
  label{
    ${tw`w-9/12 ml-3 block text-sm font-medium text-black text-xssemi`};
    div{
      label{
        color: #737373;
        margin-left:4px;
        letter-spacing: 0.03em;
        ${tw`w-full`};
      }
    }
  }
  .editBtn{
    ${tw`block w-2/12 text-right`};
    button{
      ${tw`text-xs cursor-pointer rounded-full bg-black p-2`};
    }
  }
  .flexStart{
    ${tw`flex items-start text-mini`};
  }
  svg{
    color: #CBC9C9;
    ${tw`h-4 w-4`};
  }
`;

export const NewAddressModal = styled.div`
  letter-spacing: 0.03em;
  border-color: #D8D8D8;
  ${tw`flex justify-between shadow-sm border rounded-md text-brown-darker px-4 py-4 mb-3`};
  label{
    ${tw`text-xssemi`};
  }
`;