import React from 'react';
import { useObservable } from "@libreact/use-observable";

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Checkout from '../components/Checkout';
import { selectUser$ } from "../state/user/user.service";
import { getAccessToken, isRunTime, setLocalStorageItem } from "../state/utils";
import { I18nextContext, useI18next } from "gatsby-plugin-react-i18next";

const CheckoutPage: React.FC = () => {
  const { navigate } = useI18next();
  const { language } = React.useContext(I18nextContext);
  const [user] = useObservable(selectUser$);
  const accessToken = getAccessToken();

  if (isRunTime() && !accessToken && !user.id) {
    setLocalStorageItem('uStoreAccessRedirectBackTo', '/checkout/');
    navigate('/login/')
  }

  return (
    <Layout locale={language}>
        <SEO title="Checkout" />
        <Checkout locale={language}/>
    </Layout>
  );
};

export default CheckoutPage;
