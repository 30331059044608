import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrap = styled.div`
  ${tw`bg-white mb-6 pb-6 px-4 sm:px-0`};
  margin-bottom: 32px;
  label{
    ${tw`text-blacku-medium text-xs`};
  }
  input{
    letter-spacing: 0.3px;
    ${tw`text-brown-darker text-xssemi`};
  }
  .pBase{
    margin: 24px 16px 0;
  }
`;

export const HeaderWrap = styled.h4`
  line-height: 19px;
  letter-spacing: 0.04em;
  padding: 12px 16px 10px 16px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: #4D4545;
  ${tw`border-b border-gray-200`};
`;

export const GridWrap = styled.div`
  margin: 24px 16px 0;
  ${tw`grid grid-cols-2 gap-6`};
  label{
    ${tw`w-full font-sans font-medium tracking-normal text-blacku-medium text-xs`};
  }
  input{
    ${tw`mt-1 block w-full text-xs`};
  }
`;

export const ColWrap = styled.div`
  ${tw`col-span-2`};
`;

export const Col2Wrap = styled.div`
  margin-top:0 !important;
  ${tw`col-span-2 sm:col-span-1 flex flex-col`};
`;

export const CheckBoxWrap = styled.div`
  ${tw`flex items-start text-xssemi text-brown-darker`};
  .checkField{
    ${tw`flex items-center h-5`};
    input{${tw`h-4`};}
    label{
      color:#0963CD;
      ${tw`focus:ring-blue-500 w-4 border-gray-300 rounded`};
    }
  }
  .checkLabel{
    ${tw`ml-3 text-xs`};
    label{
      ${tw`font-normal`};
    }
    a{
      color:#0963CD;
    }
  }
`;

export const ButtonWrap = styled.div`
  ${tw`w-full items-start text-xssemi text-brown-darker`};
  button{
    background: #E9E9E9;
    color: #707070;
    letter-spacing: 0.05em;
    ${tw`w-full justify-center py-2 px-4 text-base font-medium  hover:bg-black hover:text-white`};
  }
`;

export const Desktop = styled.div`
  ${tw`hidden sm:block`};
  button{
    margin-top: 0;
  }
`;

export const Mobile = styled.div`
  ${tw`block sm:hidden`};
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 24px 16px 0;

  .header{
    ${tw`text-center mb-8 mt-4 px-4`};
    h1{
      letter-spacing: 0.04em;
      ${tw`text-grayu-basic text-xl3 font-bold`};
    }
    p{ ${tw`text-sm text-brown-darker`}; }
  }

  .enabledBtn{
    ${tw`bg-blacku-default text-white w-full`};
    margin: 0;
  }

  h3{
    letter-spacing: 0.04em;
    ${tw`text-brown-semidark text-base font-semibold`};
  }

`;
