import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import DatePicker from "react-datepicker";
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import NewAddressForm from './../NewAddress/NewAddressForm.js';
import DeliveryAccept from './../OrderNotes/deliveryAccept.js';
import Danger from '../../ui/Alert/Danger';
import * as Styled from './styles';
import Error from '../../../assets/images/error.png';
import Check from '../../../assets/images/icon-check.png';

import {
  completeProfile,
  getCustomerProfile,
  selectDefaultCard$,
} from '../../../state/user/user.service';
import {
  selectPostcode$,
  setCheckoutAddressId,
  setCheckoutCardId,
} from '../../../state/checkout/checkout.service';
import { graphql, useStaticQuery } from 'gatsby';
import { calculateAge, interpolateTranslation } from '../../../state/utils';
import { startOfDay, startOfToday, } from "date-fns";

const CheckoutIncompleteProfile = ({ user, locale }) => {
  const { navigate } = useI18next();
  const gql = useStaticQuery(graphql`
    query {
      strapiCheckoutPage_en: strapiCheckoutPage(locale: { eq: "en" }) {
        account_setup_heading
        account_setup_first_name_field_label
        account_setup_last_name_field_label
        account_setup_email_field_label
        account_setup_phone_field_label
        account_setup_birth_date_field_label
        account_setup_agree_to_terms_text
        account_setup_agree_to_privacy_text
        delivery_address_form_heading
        delivery_address_info_for_drivers
        delivery_details
        specify_who_recieves_delivery
        create_account_button_text
        confirm_and_continue
        continue_to_checkout
        proceed_to_payment
        mobile_account_setup_caption
      }
      strapiCheckoutPage_zh: strapiCheckoutPage(locale: { eq: "zh" }) {
        account_setup_heading
        account_setup_first_name_field_label
        account_setup_last_name_field_label
        account_setup_email_field_label
        account_setup_phone_field_label
        account_setup_birth_date_field_label
        account_setup_agree_to_terms_text
        account_setup_agree_to_privacy_text
        delivery_address_form_heading
        delivery_address_info_for_drivers
        delivery_details
        specify_who_recieves_delivery
        create_account_button_text
        confirm_and_continue
        continue_to_checkout
        proceed_to_payment
        mobile_account_setup_caption
      }

      strapiErrorMessages_en: strapiErrorMessages(locale: { eq: "en" }) {
        email_address_required
        invalid_email_address
        unknown_error
        server_error_500
        server_error_502
        request_error_422
        over_18_required_for_an_account
        minimum_characters
        maximum_characters
        valid_phone_number
        agree_to_terms
        agree_to_privacy
        enter_billing_address
        enter_city
        enter_state
        enter_postal_code
        enter_address_label
      }
      strapiErrorMessages_zh: strapiErrorMessages(locale: { eq: "zh" }) {
        email_address_required
        invalid_email_address
        unknown_error
        server_error_500
        server_error_502
        request_error_422
        over_18_required_for_an_account
        minimum_characters
        maximum_characters
        valid_phone_number
        agree_to_terms
        agree_to_privacy
        enter_billing_address
        enter_city
        enter_state
        enter_postal_code
        enter_address_label
      }
      strapiNavigationMenu_en: strapiNavigationMenu(locale: { eq: "en" }) {
        go_back
      }
      strapiNavigationMenu_zh: strapiNavigationMenu(locale: { eq: "zh" }) {
        go_back
      }
    }
  `);

  const translations = gql[`strapiCheckoutPage_${locale}`];
  const errTranslations = gql[`strapiErrorMessages_${locale}`];
  const navTranslations = gql[`strapiNavigationMenu_${locale}`];

  const [unMount$] = useState(new Subject<void>());
  const [defaultCard, setDefaultCard] = useState(null);
  const [showAccountDetails, setShowAccountDetails] = useState(true);
  const [showDeliveryAddress, setShowDeliveryAddress] = useState(false);
  const [showDeliveryDetails, setShowDeliveryDetails] = useState(false);
  const [postCode, setPostcode] = useState(null);
  const [errorsA, setErrors] = useState([]);

  //const countErrors = document.getElementsByClassName('errorInput').length;
  const [countErr, setCountErr] = useState(1);
  const passValidate = 0;
  const inputFieldsTotal = 0;

  useEffect(() => {
    selectDefaultCard$.pipe(takeUntil(unMount$)).subscribe((cc) => {
      setDefaultCard(cc);
    });

    selectPostcode$.pipe(takeUntil(unMount$)).subscribe((postcode) => {
      setPostcode(postcode);
    });

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  const validate = (values) => {
    const errors: any = {};

    const today = startOfToday();
    const selectedDate = values.birth_date ? startOfDay(values.birth_date) : today;

    setCountErr(0);

    if (calculateAge(selectedDate) < 18) {
      errors.birth_date = errTranslations.over_18_required_for_an_account;
      setCountErr(1);
    }

    if (!values.birth_date) {
      errors.birth_date = errTranslations.over_18_required_for_an_account;
      passValidate + 1;
      setCountErr(1);
    }

    if (!values.first_name) {
      //errors.first_name = interpolateTranslation(errTranslations.minimum_characters, [`CHAR_COUNT_TOKEN`], [`2`])
      errors.first_name = locale === 'en' ? 'First name must not be empty' : '名字不能为空';
      setCountErr(1);
    } else if (values.first_name.length > 50) {
      errors.first_name = interpolateTranslation(
        errTranslations.maximum_characters,
        [`CHAR_COUNT_TOKEN`],
        [`50`]
      );
      setCountErr(1);
    }

    if (!values.last_name) {
      //errors.last_name = interpolateTranslation(errTranslations.minimum_characters, [`CHAR_COUNT_TOKEN`], [`2`])
      errors.last_name = locale === 'en' ? 'Last name must not be empty' : '姓氏不能为空';
      setCountErr(1);
    } else if (values.last_name.length > 50) {
      errors.last_name = interpolateTranslation(
        errTranslations.maximum_characters,
        [`CHAR_COUNT_TOKEN`],
        [`50`]
      );
      setCountErr(1);
    }

    if (!values.email) {
      errors.email = errTranslations.email_address_required;
      setCountErr(1);
    }

    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = errTranslations.invalid_email_address;
      setCountErr(1);
    }

    if (!values.phone || isNaN(values.phone) || values.phone.length < 10) {
      errors.phone = errTranslations.valid_phone_number;
      setCountErr(1);
      passValidate + 1;
    }

    if (!values.agree_terms) {
      errors.agree_terms = errTranslations.agree_to_terms;
      setCountErr(1);
    }

    if (!values.agree_privacy) {
      errors.agree_privacy = errTranslations.agree_to_privacy;
      setCountErr(1);
    }

    if (!values.address_line_1) {
      errors.address_line_1 = errTranslations.enter_billing_address;
      setCountErr(1);
    }

    if (!values.city) {
      errors.city = errTranslations.enter_city;
      setCountErr(1);
    }

    if (!values.state) {
      errors.state = errTranslations.enter_state;
      setCountErr(1);
    }

    if (!values.postal_code) {
      errors.postal_code = errTranslations.enter_postal_code;
      setCountErr(1);
    }

    if (countErr === inputFieldsTotal) {
      // setCountErr(0)
    }

    return errors;
  };

  function showAccountDetailsForm() {
    setShowDeliveryAddress(false);
    setShowAccountDetails(true);
  }

  function showDelivery() {
    setShowDeliveryAddress(true);
    setShowAccountDetails(false);
  }

  return (
    <div className="overflow-hidden">
      <Formik
        enableReinitialize
        initialValues={{
          first_name: user?.first_name || '',
          last_name: user?.last_name || '',
          email: user?.email || '',
          birth_date: user?.birth_date || '',
          phone: user?.phone || '',
          agree_terms: false,
          agree_privacy: false,
          address_line_1: '',
          label: '',
          city: postCode?.name.split(' - ')[1],
          state: postCode?.name.split(' - ')[2],
          postal_code: postCode?.name.split(' - ')[0],
          country: 'AU',
          submit: false,
        }}
        validate={validate}
        onSubmit={(values) => {
          if (defaultCard) {
            setCheckoutCardId(defaultCard.id);
          }
          completeProfile(values).subscribe(
            async (address) => {
              setCheckoutAddressId(address.id);
              await getCustomerProfile();
              await navigate('/delivery/');
            },
            (err) => {
              switch (err.status) {
                case 500:
                  setErrors([errTranslations.server_error_500]);
                  break;
                case 502:
                  setErrors([errTranslations.server_error_502]);
                  break;
                case 400:
                  setErrors([errTranslations.request_error_422]);
                  break;
                case 401:
                  setErrors([errTranslations.invalid_login_credentials]);
                  break;
                case 422:
                  setErrors([errTranslations.request_error_422]);
                  break;
                default:
                  setErrors([errTranslations.unknown_error]);
                  break;
              }
            }
          );
        }}
      >
        {({ values, validateForm, submitForm, isValid, dirty, setFieldValue }) => (
          <Form>
            <Styled.Wrap>
              {showAccountDetails && (
                <>
                  <Styled.Desktop>
                    <Styled.HeaderWrap>{translations.account_setup_heading}</Styled.HeaderWrap>
                  </Styled.Desktop>
                  <Styled.Mobile>
                    <div className="header">
                      <h1>{translations.account_setup_heading}</h1>
                      <p>{translations.mobile_account_setup_caption}</p>
                    </div>
                  </Styled.Mobile>

                  <Styled.GridWrap>
                    <Styled.Col2Wrap>
                      <label htmlFor="first_name">
                        {' '}
                        {translations.account_setup_first_name_field_label}{' '}
                      </label>
                      <Field type="first_name" name="first_name">
                        {({ field, meta: { touched, error } }) => (
                          <div className="relative">
                            <input className={touched && error ? 'errorInput' : ''} {...field} />
                            <img
                              src={touched && error ? Error : Check}
                              alt="error"
                              className={!touched ? 'errorIconHide' : 'errorIconShow'}
                            />
                          </div>
                        )}
                      </Field>
                      <ErrorMessage name="first_name" component="div" className="errorMessage" />
                    </Styled.Col2Wrap>
                    <Styled.Col2Wrap>
                      <label htmlFor="last_name">
                        {' '}
                        {translations.account_setup_last_name_field_label}{' '}
                      </label>
                      <Field type="last_name" name="last_name">
                        {({ field, meta: { touched, error } }) => (
                          <div className="relative">
                            <input className={touched && error ? 'errorInput' : ''} {...field} />
                            <img
                              src={touched && error ? Error : Check}
                              alt="error"
                              className={!touched ? 'errorIconHide' : 'errorIconShow'}
                            />
                          </div>
                        )}
                      </Field>
                      <ErrorMessage name="last_name" component="div" className="errorMessage" />
                    </Styled.Col2Wrap>
                  </Styled.GridWrap>

                  <Styled.GridWrap>
                    <Styled.Col2Wrap>
                      <label htmlFor="emailAddress">
                        {' '}
                        {translations.account_setup_email_field_label}{' '}
                      </label>
                      <Field type="email" name="email">
                        {({ field, meta: { touched, error } }) => (
                          <div className="relative">
                            <input className={touched && error ? 'errorInput' : ''} {...field} />
                            <img
                              src={touched && error ? Error : Check}
                              alt="error"
                              className={!touched ? 'errorIconHide' : 'errorIconShow'}
                            />
                          </div>
                        )}
                      </Field>
                      <ErrorMessage name="email" component="div" className="errorMessage" />
                    </Styled.Col2Wrap>

                    <Styled.Col2Wrap>
                      <label htmlFor="contactNumber">
                        {' '}
                        {translations.account_setup_phone_field_label}{' '}
                      </label>
                      <Field type="tel" name="phone" maxLength="10">
                        {({ field, meta: { touched, error } }) => (
                          <div className="relative">
                            <input
                              type="tel"
                              maxLength="10"
                              className={touched && error ? 'errorInput' : ''}
                              {...field}
                              id="phone"
                            />
                            <img
                              src={touched && error ? Error : Check}
                              alt="error"
                              className={!touched ? 'errorIconHide' : 'errorIconShow'}
                            />
                          </div>
                        )}
                      </Field>
                      <ErrorMessage name="phone" component="div" className="errorMessage" />
                    </Styled.Col2Wrap>
                  </Styled.GridWrap>

                  <Styled.GridWrap>
                    <Styled.ColWrap>
                      <label htmlFor="dob">
                        {' '}
                        {translations.account_setup_birth_date_field_label}{' '}
                      </label>
                      <DatePicker
                        name="birth_date"
                        selected={values.birth_date ? new Date(values.birth_date) : null}
                        onChange={(val) => {
                          setFieldValue('birth_date', val);
                        }}
                        showYearDropdown
                        dateFormat="dd/MM/yyyy"
                        peekNextMonth
                        showMonthDropdown
                        dropdownMode="select"
                      />
                      <ErrorMessage name="birth_date" component="div" className="errorMessage" />
                    </Styled.ColWrap>
                  </Styled.GridWrap>

                  <div className="pBase">
                    <Styled.CheckBoxWrap>
                      <div className="checkField">
                        <label htmlFor="agree_terms">
                          <Field type="checkbox" name="agree_terms">
                            {({ field, meta: { touched, error } }) => (
                              <input
                                type="checkbox"
                                className={touched && error ? 'checkBoxError' : ''}
                                {...field}
                              />
                            )}
                          </Field>
                        </label>
                      </div>
                      <div className="checkLabel">
                        <label htmlFor="comments">
                          <Link to="/terms-and-condition">
                            {translations.account_setup_agree_to_terms_text}
                          </Link>
                        </label>
                        <ErrorMessage name="agree_terms" component="div" className="errorMessage" />
                      </div>
                    </Styled.CheckBoxWrap>

                    <Styled.CheckBoxWrap>
                      <div className="checkField">
                        <label htmlFor="agree_privacy">
                          <Field type="checkbox" name="agree_privacy">
                            {({ field, meta: { touched, error } }) => (
                              <input
                                type="checkbox"
                                className={touched && error ? 'checkBoxError' : ''}
                                {...field}
                              />
                            )}
                          </Field>
                        </label>
                      </div>
                      <div className="checkLabel">
                        <label htmlFor="comments">
                          <Link to="/privacy-policy">
                            {translations.account_setup_agree_to_privacy_text}
                          </Link>
                        </label>
                        <ErrorMessage
                          name="agree_privacy"
                          component="div"
                          className="errorMessage"
                        />
                      </div>
                    </Styled.CheckBoxWrap>
                  </div>
                </>
              )}

              {showDeliveryAddress && (
                <>
                  <Styled.Mobile>
                    <div className="header">
                      <h1>{translations.delivery_address_form_heading}</h1>
                      <p>{translations.delivery_address_info_for_drivers}</p>
                    </div>
                    <NewAddressForm locale={locale} />
                  </Styled.Mobile>
                </>
              )}

              {showDeliveryDetails && (
                <>
                  <Styled.Mobile>
                    <div className="header">
                      <h1>{translations.delivery_details}</h1>
                      <p>{translations.specify_who_recieves_delivery}</p>
                    </div>
                    <DeliveryAccept />
                  </Styled.Mobile>
                </>
              )}
            </Styled.Wrap>

            <Styled.Desktop>
              <Styled.Wrap>
                <Styled.HeaderWrap>{translations.delivery_address_form_heading}</Styled.HeaderWrap>
                <Styled.GridWrap>
                  <Styled.ColWrap>
                    <fieldset>
                      <NewAddressForm locale={locale} />
                    </fieldset>

                    {errorsA.map((err) => (
                      <Danger message={err} />
                    ))}
                  </Styled.ColWrap>
                </Styled.GridWrap>
              </Styled.Wrap>
            </Styled.Desktop>

            <Styled.Desktop>
              <button type="submit" id="submitBtn" className="enabledBtn">
                {translations.create_account_button_text}
              </button>
            </Styled.Desktop>

            <Styled.ButtonWrap>
              <Styled.Mobile>
                {!showDeliveryAddress && !showDeliveryDetails && (
                  <>
                    <button
                      type="button"
                      className="enabledBtn"
                      onClick={async () => {
                        const validatedForm = await validateForm(values);

                        let passed = true;
                        const checkFields = [
                          'first_name',
                          'last_name',
                          'email',
                          'phone',
                          'birth_date',
                          'agree_privacy',
                          'agree_terms',
                        ];

                        for (const fieldName of checkFields) {
                          if (validatedForm[fieldName]) {
                            passed = false;
                            await submitForm();
                            break;
                          }
                        }

                        if (passed) {
                          showDelivery();
                        }
                      }}
                    >
                      {translations.confirm_and_continue}
                    </button>

                    <button className="mt-3" type="button" onClick={() => navigate('/shop')}>
                      {navTranslations.go_back}
                    </button>
                  </>
                )}

                {showDeliveryAddress && (
                  <>
                    <button
                      type="submit"
                      className={
                        isValid && dirty ? 'enabledBtn' : 'bg-gray-200 w-full text-gray-500'
                      }
                    >
                      {translations.continue_to_checkout}
                    </button>
                    <button className="mt-3" type="button" onClick={() => showAccountDetailsForm()}>
                      {navTranslations.go_back}
                    </button>
                  </>
                )}

                {showDeliveryDetails && (
                  <Link to="/payment">
                    <button type="submit">{translations.proceed_to_payment}</button>
                  </Link>
                )}
              </Styled.Mobile>
            </Styled.ButtonWrap>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CheckoutIncompleteProfile;
