import React, { useEffect, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import * as Styled from './styles';
import NewAddressForm from './NewAddressForm';
import { Form, Formik } from 'formik';
import {
  addCustomerAddress, deleteCustomerAddress,
  getCustomerProfile, selectUser$,
  updateCustomerAddress,
} from '../../../state/user/user.service';
import { setCheckoutAddressId } from '../../../state/checkout/checkout.service';
import { graphql, useStaticQuery } from "gatsby";
import { catchError, take, takeUntil } from "rxjs/operators";
import TrashIcon from '../../ui/icons/trash';
import PencilIcon from '../../ui/icons/pencil';
import MapIcon from '../../ui/icons/map';
import ArrowRight from '../../ui/icons/arrowRight';
import { Subject } from "rxjs";
import Button from "../../ui/Button";

const AddressList = ({ addresses, chooseAddress, selectedAddress, locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiCheckoutPage_en: strapiCheckoutPage(locale: { eq: "en" }) {
        add_new_address
        add_new_address_caption
        edit_address
        save_address_button_text
        update_address_button_text
        delete_address_button_text
      }
      strapiCheckoutPage_zh: strapiCheckoutPage(locale: { eq: "zh" }) {
        add_new_address
        add_new_address_caption
        edit_address
        save_address_button_text
        update_address_button_text
        delete_address_button_text
      }
      strapiErrorMessages_en: strapiErrorMessages(locale: { eq: "en" }) {
        unknown_error
        server_error_500
        server_error_502
        request_error_422
        enter_billing_address
        enter_city
        enter_state
        enter_postal_code
        enter_address_label
      }
      strapiErrorMessages_zh: strapiErrorMessages(locale: { eq: "zh" }) {
        unknown_error
        server_error_500
        server_error_502
        request_error_422
        enter_billing_address
        enter_city
        enter_state
        enter_postal_code
        enter_address_label
      }
    }
  `);
  const translations = gql[`strapiCheckoutPage_${locale}`];
  const errTranslations = gql[`strapiErrorMessages_${locale}`];
  const [showNewAddressModal, setShowNewAddressModal] = useState(false);
  const [editingAddress, setEditingAddress] = useState({});
  const [mode, setMode] = useState('edit');
  const [user, setUser] = useState(null);
  const [unMount$] = useState(new Subject());

  useEffect(() => {
    selectUser$.pipe(takeUntil(unMount$)).subscribe((user) => {
      setUser(user);
    });

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  const onDismiss = () => {
    setShowNewAddressModal(false);
  };

  const addNewAddress = () => {
    setShowNewAddressModal(true);
    setEditingAddress({});
    setMode('add');
  };

  const editAddress = (address) => {
    setShowNewAddressModal(true);
    setEditingAddress(address);
    setMode('edit');
  };

  const validate = (values) => {
    const errors = {};

    if (!values.address_line_1) {
      errors.address_line_1 = errTranslations.enter_billing_address;
    }

    if (!values.city) {
      errors.city = errTranslations.enter_city;
    }

    if (!values.state) {
      errors.state = errTranslations.enter_state;
    }

    if (!values.postal_code) {
      errors.postal_code = errTranslations.enter_postal_code;
    }

    return errors;
  };

  return (
    <>
      {addresses.map((address) => (
        <Styled.Address>
          <input
            type="radio"
            checked={selectedAddress === address.id}
            onChange={() => chooseAddress(address)}
          />
          <label htmlFor="push-email">
            {address.label}
            <div className="flexStart">
              <div className="flex items-center py-1.5">
                <MapIcon/>
              </div>
              <div>
                <label htmlFor="candidates">
                  {[
                    address.address_line_1,
                    address.address_line_2,
                    address.city,
                    address.state,
                    address.postal_code,
                  ].join(' ')}
                </label>
              </div>
            </div>
          </label>
          <div className="editBtn">
            <button type="button" onClick={() => editAddress(address)}>
              <PencilIcon/>
            </button>
          </div>
        </Styled.Address>
      ))}

      <Styled.NewAddressModal onClick={() => addNewAddress()}>
        <label htmlFor="push-email">{ translations.add_new_address }</label>
        <ArrowRight/>
      </Styled.NewAddressModal>

      <BottomSheet
        open={showNewAddressModal}
        onDismiss={onDismiss}
        snapPoints={({ minHeight }) => minHeight}
      >
        <div className='w-full px-6 pt-6'>
          <div className='flex justify-between'>
            {mode === 'edit' && <h1 className={`mb-2 text-base font-bold`}>{translations.edit_address} </h1>}
            {mode === 'edit' && user && user.addresses && user.addresses.length > 1 && <p  onClick={(e) => {
              e.preventDefault();
              deleteCustomerAddress(editingAddress.id)
                .pipe(take(1))
                .subscribe(async () => {
                  await getCustomerProfile();
                  setShowNewAddressModal(false);
                });
            }}
            className='p-2 bg-black text-white rounded-full'
            ><TrashIcon/></p>}
          </div>

          {mode === 'add' && <h1 className={`mb-2 text-base font-bold`}>{translations.add_new_address_caption}</h1>}
        </div>
        <div className="w-full pb-4 px-6 z-20 pb-4">
          <Formik
            enableReinitialize
            initialValues={{
              address_line_1: editingAddress.address_line_1,
              city: editingAddress.city,
              state: editingAddress.state,
              postal_code: editingAddress.postal_code,
              label: editingAddress.label,
              country: 'AU',
            }}
            validate={validate}
            onSubmit={(values, actions) => {
              if (mode === 'add') {
                addCustomerAddress(values).pipe(catchError((err) => {
                  console.log('add address err', err);
                })).subscribe(
                  async (address) => {
                    setCheckoutAddressId(address.id);
                    await getCustomerProfile();
                    setShowNewAddressModal(false);
                    actions.resetForm();
                  }
                );
              } else if (mode === 'edit') {
                updateCustomerAddress(editingAddress.id, values).pipe(catchError((err) => {
                  console.log('update address err', err);
                })).subscribe(
                  async () => {
                    await getCustomerProfile();
                    setShowNewAddressModal(false);
                    actions.resetForm();
                  }
                );
              }
            }}
          >
            {({isValid, dirty}) => (
              <Form>
                <NewAddressForm locale={locale} />
                <div className="block sm:hidden">
                  {mode === 'edit' && (
                    <button className={`w-full py-2 mt-4 text-base ${ isValid ? "bg-black text-white" : "bg-grayu-medium2 text-brown-lightmd"}`}>
                      {translations.update_address_button_text}
                    </button>
                  )}
                  {mode === 'add' && (
                    <button className={`w-full py-2 mt-4 text-base ${ isValid && dirty ? "bg-black text-white" : "bg-grayu-medium2 text-brown-lightmd"}`}>
                      {translations.save_address_button_text}
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </BottomSheet>
    </>
  );
};

export default AddressList;
