import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import PropTypes from 'prop-types';
import { filter, takeUntil } from 'rxjs/operators';
import { Formik } from 'formik';
import * as Styled from './styles';
import NewAddress from '../NewAddress';
import AddressList from './../../Checkout/NewAddress/AddressList.js';
import {
  addCustomerAddress,
  getCustomerOrders,
  getCustomerProfile,
  selectDefaultCard$,
  selectUserAddresses$,
  updateCustomerAddress,
} from '../../../state/user/user.service';
import { setCheckoutAddressId, setCheckoutCardId } from '../../../state/checkout/checkout.service';
import { selectCart$ } from '../../../state/cart/cart.service';
import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import GoBackLink from './../../ui/GoBackLink';

import MapIcon from './../../ui/icons/map';

const CheckoutCompletedProfile = ({ user, locale }) => {
  const { navigate } = useI18next();
  const gql = useStaticQuery(graphql`
    query {
      strapiCheckoutPage_en: strapiCheckoutPage(locale: { eq: "en" }) {
        heading
        main_caption
        edit_address
        continue_to_checkout
        back_to_shop_link_text
        default_address_text
        delivery_mobile_heading
        select_address_mobile_heading
      }
      strapiCheckoutPage_zh: strapiCheckoutPage(locale: { eq: "zh" }) {
        heading
        main_caption
        edit_address
        continue_to_checkout
        back_to_shop_link_text
        default_address_text
        delivery_mobile_heading
        select_address_mobile_heading
      }
      strapiErrorMessages_en: strapiErrorMessages(locale: { eq: "en" }) {
        unknown_error
        server_error_500
        server_error_502
        request_error_422
        enter_billing_address
        enter_city
        enter_state
        enter_postal_code
        enter_address_label
      }
      strapiErrorMessages_zh: strapiErrorMessages(locale: { eq: "zh" }) {
        unknown_error
        server_error_500
        server_error_502
        request_error_422
        enter_billing_address
        enter_city
        enter_state
        enter_postal_code
        enter_address_label
      }
    }
  `);
  const translations = gql[`strapiCheckoutPage_${locale}`];
  const errTranslations = gql[`strapiErrorMessages_${locale}`];
  const [errors, setErrors] = useState([]);
  const [unMount$] = useState(new Subject<void>());
  const [closeAddressForm$] = useState(new Subject<boolean>());
  const [addresses, setAddresses] = useState([]);
  const [defaultCard, setDefaultCard] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedAddressExist, setSelectedAddressExist] = useState(false);
  const [totalUniqueItems, setTotalUniqueItems] = useState(0);

  useEffect(() => {
    selectDefaultCard$.pipe(takeUntil(unMount$)).subscribe((cc) => {
      setDefaultCard(cc);
    });

    selectUserAddresses$.pipe(takeUntil(unMount$)).subscribe((addresses) => {
      setAddresses(addresses);
    });

    selectCart$.pipe(takeUntil(unMount$)).subscribe((cart) => {
      const uniq = cart.products?.length || 0;
      setTotalUniqueItems(uniq);
    });

    getCustomerOrders()
      .pipe(
        takeUntil(unMount$),
        filter((orders) => orders.length)
      )
      .subscribe((orders) => {
        setSelectedAddress(orders[0].customer_address_id);
      });

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  useEffect(() => {
    selectUserAddresses$.pipe(takeUntil(unMount$)).subscribe((addresses) => {
      setSelectedAddressExist(!!addresses.find((address) => address.id === selectedAddress));
    });
  }, [selectedAddress]);

  const _setSelectedAddress = (address) => {
    setSelectedAddress(address.id);
    setCheckoutAddressId(address.id);
  };

  const validate = (values) => {
    const errors: any = {};

    if (!values.address_line_1) {
      errors.address_line_1 = errTranslations.enter_billing_address;
    }

    if (!values.city) {
      errors.city = errTranslations.enter_city;
    }

    if (!values.state) {
      errors.state = errTranslations.enter_state;
    }

    if (!values.postal_code) {
      errors.postal_code = errTranslations.enter_postal_code;
    }


    return errors;
  };

  const continueToCheckout = async () => {
    if (defaultCard) {
      setCheckoutCardId(defaultCard.id);
    }

    await navigate('/delivery/');
    setCheckoutAddressId(selectedAddress);
  };

  return (
    <div className=" overflow-hidden">
      <Styled.Wrap>
        <Styled.Desktop>
          <Styled.HeaderWrap>
            {translations.heading} {user.first_name}!
          </Styled.HeaderWrap>
        </Styled.Desktop>
        <Styled.GridWrap>
          <Styled.Desktop>
            <p className="pTag">{translations.main_caption}</p>
            <Styled.ColWrap>
              {addresses.map((address) => (
                <Styled.Address
                  key={address.id}
                  className={`mb-2 ${selectedAddress === address.id ? 'active' : ''}`}
                >
                  <div>
                    <input
                      type="radio"
                      checked={selectedAddress === address.id}
                      onChange={() => _setSelectedAddress(address)}
                    />
                  </div>

                  <label htmlFor="push-email" className="flex-grow">
                    {address.label}
                    <div className="flexStart">
                      <div className="flex items-center py-1.5">
                        <MapIcon />
                      </div>
                      <div>
                        <label htmlFor="candidates">
                          {[
                            address.address_line_1,
                            address.address_line_2,
                            address.city,
                            address.state,
                            address.postal_code,
                          ].join(' ')}
                        </label>
                      </div>
                    </div>
                  </label>

                  <div className="editBtn">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        address_line_1: address.address_line_1,
                        city: address.city,
                        state: address.state,
                        postal_code: address.postal_code,
                        label: address.label,
                        country: 'AU',
                      }}
                      validate={validate}
                      onSubmit={(values, actions) => {
                        setErrors([]);
                        updateCustomerAddress(address.id, values).subscribe(
                          async () => {
                            await getCustomerProfile();
                            closeAddressForm$.next(true);
                            actions.resetForm();
                          },
                          (ajax) => {
                            switch (ajax.status) {
                              case 500:
                                setErrors([errTranslations.server_error_500]);
                                break;
                              case 502:
                                setErrors([errTranslations.server_error_502]);
                                break;
                              case 400:
                                setErrors([errTranslations.request_error_422]);
                                break;
                              case 401:
                                setErrors([errTranslations.invalid_login_credentials]);
                                break;
                              case 422:
                                setErrors([errTranslations.request_error_422]);
                                break;
                              default:
                                setErrors([errTranslations.unknown_error]);
                                break;
                            }

                            setTimeout(() => {
                              setErrors([]);
                            }, 3000);
                          }
                        );
                      }}
                    >
                      {() => (
                        <NewAddress
                          locale={locale}
                          type="edit"
                          closeAddressForm={closeAddressForm$}
                          address={address}
                          errors={errors}
                        />
                      )}
                    </Formik>
                  </div>
                </Styled.Address>
              ))}

              <Formik
                enableReinitialize
                initialValues={{
                  address_line_1: '',
                  city: '',
                  state: '',
                  postal_code: '',
                  label: '',
                  country: 'AU',
                }}
                validate={validate}
                onSubmit={(values, actions) => {
                  setErrors([]);
                  addCustomerAddress(values).subscribe(
                    async (address) => {
                      setCheckoutAddressId(address.id);
                      await getCustomerProfile();
                      closeAddressForm$.next(true);
                      actions.resetForm();
                    },
                    (ajax) => {
                      switch (ajax.status) {
                        case 500:
                          setErrors([errTranslations.server_error_500]);
                          break;
                        case 502:
                          setErrors([errTranslations.server_error_502]);
                          break;
                        case 400:
                          setErrors([errTranslations.request_error_422]);
                          break;
                        case 401:
                          setErrors([errTranslations.invalid_login_credentials]);
                          break;
                        case 422:
                          setErrors([errTranslations.request_error_422]);
                          break;
                        default:
                          setErrors([errTranslations.unknown_error]);
                          break;
                      }

                      setTimeout(() => {
                        setErrors([]);
                      }, 3000);
                    }
                  );
                }}
              >
                {() => (
                  <NewAddress
                    locale={locale}
                    type="new"
                    closeAddressForm={closeAddressForm$}
                    errors={errors}
                  />
                )}
              </Formik>
            </Styled.ColWrap>
          </Styled.Desktop>
        </Styled.GridWrap>
      </Styled.Wrap>

      <Styled.Mobile>
        <div className="header">
          <h1>{translations.delivery_mobile_heading}</h1>
          <p>{translations.select_address_mobile_heading}</p>
        </div>

        <Styled.ColWrap className="mx-4">
          <AddressList
            addresses={addresses}
            chooseAddress={_setSelectedAddress}
            selectedAddress={selectedAddress}
            locale={locale}
          />
        </Styled.ColWrap>
      </Styled.Mobile>
      <Styled.ButtonWrap>
        {totalUniqueItems > 0 ? (
          selectedAddressExist && (
            <button type="submit" className="main" onClick={continueToCheckout}>
              {translations.continue_to_checkout}
            </button>
          )
        ) : (
          <button type="button" disabled className="disabledBtn">
            {locale === 'en' ? 'Cart is empty' : '购物车是空的'}
          </button>
        )}

        <div>
          <GoBackLink title={translations.back_to_shop_link_text} link="/shop" />
        </div>
      </Styled.ButtonWrap>
    </div>
  );
};

CheckoutCompletedProfile.propTypes = {
  locale: PropTypes.string,
};

CheckoutCompletedProfile.defaultProps = {
  locale: 'en',
};

export default CheckoutCompletedProfile;
